import { Row, Col, Image } from "react-bootstrap";
import { useCallback, useState } from "react";

function NftList({ items, limit, didSelect, filter }) {
  const [selected, setSelected] = useState([]);

  const callbackFunction = useCallback(
    (selected) => {
      if (didSelect) {
        console.log(selected);
        didSelect(selected);
      }
    },
    [didSelect]
  );

  const itemSelected = (e, item) => {
    var cloned = [...selected];
    if (cloned.includes(item.name)) {
      cloned = cloned.filter((el) => el !== item.name);
    } else {
      cloned.push(item.name);
    }
    if (cloned.length < limit) {
      setSelected(cloned);
    }

    const all = [].concat.apply([], items);
    callbackFunction(
      all
        .filter((el) => cloned.includes(el.name))
        .map((el) => (filter ? filter(el) : el))
    );
  };

  return (
    <>
      {items.map((item, index) => {
        return (
          <Col
            xs={4}
            sm={2}
            md={3}
            lg={2}
            key={`col_${index}`}
            className="justify-content-md-center mr-2 ml-2 mb-5 col-soy"
          >
            <Row className="justify-content-center align-items-center content">
              <div
                className={
                  selected.includes(item.name)
                    ? "content-overlay-visible"
                    : "content-overlay"
                }
                onClick={(e) => {
                  itemSelected(e, item);
                }}
              ></div>
              <Image
                className="soy-img"
                src={
                  process.env.REACT_APP_SOY_DARK
                    ? item.image
                    : item.image.replace(
                        "metadata.soy.xyz/images",
                        "edit.soy.xyz/images"
                      )
                }
                alt="loading..."
                roundedCircle
                onClick={(e) => {
                  itemSelected(e, item);
                }}
                crossOrigin="anonymous"
              />
              <div
                hidden={!selected.includes(item.name)}
                className={
                  selected.includes(item.name)
                    ? "content-details-visible"
                    : "content-details"
                }
                onClick={(e) => {
                  itemSelected(e, item);
                }}
              >
                <div className="content-details-text">
                  {selected.indexOf(item.name) + 1}
                </div>
              </div>
            </Row>
            <Row className="justify-content-center align-items-center ">
              <div className="soy-info">{item.name}</div>
            </Row>
          </Col>
        );
      })}
    </>
  );
}

export default NftList;
