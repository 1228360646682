import Web3 from "web3";
import * as Sentry from "@sentry/react";
const contractABI = require("../contracts/ShadesOfYou.json");
const contractABIDark = require("../contracts/DarkSideShadies.json");
const contractAddress = "0xa3240c269ad0ebef13647c726e532e964c55ef3d";
const contractAddressDark = "0x9a8b98Bd84D808a1E3859D87a3A114977F991F34"; //"0x624187042e36E1e4F25b545b6151ebcF99F5fD18"  "0x9a8b98Bd84D808a1E3859D87a3A114977F991F34"

export async function getTokensData(address) {
  try {
    const web3Provider = new Web3(
      `wss://mainnet.infura.io/ws/v3/${process.env.REACT_APP_INFURA_APP_ID}`
    );
    const contract = process.env.REACT_APP_SOY_DARK
      ? new web3Provider.eth.Contract(contractABIDark.abi, contractAddressDark)
      : new web3Provider.eth.Contract(contractABI.abi, contractAddress);
    const balance = await contract.methods.balanceOf(address).call();
    if (balance === 0) {
      return [];
    }
    const tokensData = await Promise.all(
      Array.from({ length: balance }, (_, i) => i).map(async (index) => {
        const tokenId = await contract.methods
          .tokenOfOwnerByIndex(address, index)
          .call();
        return {
          tokenId,
        };
      })
    );
    return tokensData;
  } catch (err) {
    Sentry.captureException(err);
  }
  return [];
}
